@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');
@import url('https://fonts.googleapis.com/css?family=VT323');

.App {
  text-align: center;
  font-family: 'Press Start 2P', "VT323";
  color: #fff;
}

* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

.overlay {
  opacity: 0.85;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.moving-background{
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/background/parallax-bg.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 40% 40%;
}

/******************* MainMint js **************************/
.heading {
  font-size: 48px;
  text-shadow: 0 5px #000000;
}

.subheading {
  font-size: 30px;
  letter-spacing: -5.5%;
  font-family: VT323;
  text-shadow: 0 2px 2px #000000;
}

.decrementButton,
.incrementButton,
.mintButton {
  background-color: #d6517d;
  border-radius: 5px;
  box-shadow: 0 2px 2px 1px #0f0f0f;
  color: white;
  cursor: pointer;
  font-family: inherit;
  padding: 15px;
  margin-top: 10px;
}

.inputField {
  font-family: inherit;
  width: 100px;
  height: 40px;
  text-align: center;
  padding-left: 19px;
  margin-top: 10px;
}

.notConnectedText {
  margin-top: 70px;
  font-size: 30px;
  letter-spacing: -5.5%;
  font-family: VT323;
  text-shadow: 0 3px #000000;
  color: #d6517d;
}
